import React, { Fragment } from "react";
import {
  VideoOffIcon,
  VideoOnIcon,
  MicOffIcon,
  MicOnIcon,
} from "@100mslive/react-icons";
import { IconButton } from "../react-ui/src";
import { useAVToggle } from "@100mslive/react-sdk";

export const AudioVideoToggle = ({ compact = false }) => {
  const { isLocalVideoEnabled, isLocalAudioEnabled, toggleAudio, toggleVideo } =
    useAVToggle();
  return (
    <Fragment>
      {toggleAudio ? (
        <IconButton
          css={{
            mr: compact ? "$2" : "$4",
            pointerEvents: "all",
          }}
          active={isLocalAudioEnabled}
          onClick={toggleAudio}
          key="toggleAudio"
          data-testid="audio_btn"
        >
          {!isLocalAudioEnabled ? (
            <MicOffIcon data-testid="audio_off_btn" />
          ) : (
            <MicOnIcon data-testid="audio_on_btn" />
          )}
        </IconButton>
      ) : null}
      {toggleVideo ? (
        <IconButton
          css={
            compact
              ? {
                  color: "white",
                  backgroundColor: "unset",
                  pointerEvents: "all",
                }
              : {
                  mr: "$4",
                  pointerEvents: "all",
                }
          }
          active={isLocalVideoEnabled}
          key="toggleVideo"
          onClick={toggleVideo}
          data-testid="video_btn"
        >
          {!isLocalVideoEnabled ? (
            <VideoOffIcon
              style={{ pointerEvents: "all" }}
              data-testid="video_off_btn"
            />
          ) : (
            <VideoOnIcon
              style={{ pointerEvents: "all" }}
              data-testid="video_on_btn"
            />
          )}
        </IconButton>
      ) : null}
    </Fragment>
  );
};
